.App {
  
  
  height:100vh;
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem 1fr;
}

.App-content  {
  background:pink;

  font-size: calc(8px + 2vmin);
  display:flex;
  flex-direction: column;
  padding: 1rem;
  gap:2rem;
  justify-content: space-between;
}

.button-wrapper {
  display:flex;
  flex-direction: row;
  gap:0.5rem;
  justify-content: center;
  flex-wrap: wrap;
  
}

:root {
  --red: #f21332;
  --orange: #f27225;
  --pink: #e20b88;
  --yellow: #f2ad24;
  --green: #00b249;
  --blue: #1844b5;
  --purple: #a033b3;
}
button {
  cursor:pointer;
  outline: none;
  border:0;
  padding: 0.5rem 0.8rem;
  font-size: 1rem;
  font-weight: bold;
}

button:nth-of-type(7n + 1) {
  color: var(--red);
}

button:nth-of-type(7n + 2) {
  color: var(--orange);
}

button:nth-of-type(7n + 3) {
  color: var(--pink);
}

button:nth-of-type(7n + 4) {
  color: var(--yellow);
}

button:nth-of-type(7n + 5) {
  color: var(--green);
}

button:nth-of-type(7n + 6) {
  color: var(--blue);
}

button:nth-of-type(7n + 7) {
  color: var(--purple);
}

button:hover,button:focus {
  filter:brightness(90%);
}

.App-header {
  background-color: #282c34;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.info {
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  background: white;
  border-radius: 0.5rem;

}

.info p {
  padding:0 0.8rem;
  font-size:0.8rem;
  color: #333;
  min-height:6rem;
  
  word-break: break-all;
}
